
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Author {
  OutSource: boolean;
  Name?: string;
  AuthorId?: string;
  Author?: { Id: string, Name: string };
  Source?: string;
  Fee: number;
  PayStatus: number;
  Ordinal: number;
}

export default defineComponent({
  name: "DocumentAuthorTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<Author[]>,
      default: () => []
    },
    readonly: Boolean
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as Author[],
      isLoading: false,
      isEditing: false,
      formData: {} as Author,
      selectedRow: null as Author | null,
      insert() {
        table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
        table.selectedRow = null;
        table.isEditing = true;
      },
      update(row: Author) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: Author) {
        grid.value.remove(row);
      },
      async save() {
        table.isLoading = true;
        if (table.formData.OutSource) {
          table.formData.AuthorId = undefined;
          table.formData.Author = undefined;
        } else {
          table.formData.Name = undefined;
          if (table.formData.AuthorId) {
            const temp = await model?.dispatch("user/find", table.formData.AuthorId)
            table.formData.Author = { Id: temp.Id, Name: temp.Name };
          }
        }
        if (table.selectedRow) {
          Object.assign(table.selectedRow, table.formData)
        } else {
          await grid.value.insert(table.formData);
        }
        grid.value.sort("Ordinal", 'asc');
        table.isEditing = false;
        table.isLoading = false;
      }
    })

    watch(() => props.data, async (value) => {
      const authorIds = value.filter(e => e.AuthorId).map(e => e.AuthorId);
      const authors: Author['Author'][] = await model?.dispatch("user/query", { condition: new Condition("Id", Operator.In, authorIds) });
      table.data = value.map(e => {
        if (e.AuthorId) {
          e.Author = authors.find(a => a && a.Id === e.AuthorId)
          e.OutSource = false;
        } else {
          e.OutSource = true;
        }
        return e;
      }).sort((a, b) => a.Ordinal - b.Ordinal);
    }, { immediate: true });

    const form = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => model!.dispatch("user/query", params) // eslint-disable-line
      }
    };
    return {
      table,
      grid,
      gridOptions,
      form,
      userSelectorOptions
    }
  },
  methods: {
    getData() {
      // 避免 Author 帶過多關聯使的API維護Mapping
      return this.grid.getTableData().fullData.map((e: Author) => {
        delete e.Author;
        return e;
      });
    }
  }
})
