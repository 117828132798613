
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import AuthorTable from "@/components/document/AuthorTable.vue";
import RecordTable from "@/components/document/RecordTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AuthorTable,
    RecordTable
  },
  setup() {
    const model = CloudFun.current?.model;
    const user = CloudFun.current?.user;
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({});
    const isDisabled = ref(false);

    const gridOptions: GridOptions = {
      stripe: false,
      title: '撰稿管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      columns: [{
        field: 'IndustryCategoryName',
        title: '產業類別',
        width: '10%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: false,
      },
      {
        field: 'Title',
        title: '標題',
        width: '30%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      {
        field: 'ReportUser.Name',
        title: '撰寫人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ProofUser.Name',
        title: '校稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ReviewUser.Name',
        title: '審稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ReportDate',
        title: '撰稿截止日期',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd')
      },
      {
        field: 'Status',
        title: '狀態',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.DocumentStatus).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
      },
      // {
      //   field: 'TopicType',
      //   title: '主題',
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   formatter: ({ cellValue }) => {
      //     const item = model?.enums && Object.values(model?.enums.TopicType).find((e) => e.Value === cellValue);
      //     return item ? item.Name : "";
      //   }
      // },
      {
        title: '標題變更',
        width: '100',
        slots: { default: "edit-title" }
      },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          params.condition = new Condition("ReportUserId", Operator.Equal, user?.Id)
            .and(
              new Condition("Status", Operator.Equal, enums.DocumentStatus.Writing.Value)
                .or("Status", Operator.Equal, enums.DocumentStatus.ProofReadReturn.Value)
                .or("Status", Operator.Equal, enums.DocumentStatus.ReviewedReturn.Value)
                .or("Status", Operator.Equal, enums.DocumentStatus.Changing.Value)
                .or("Status", Operator.Equal, enums.DocumentStatus.ChangedReturn.Value)
                .or("Status", Operator.Equal, enums.DocumentStatus.Changed.Value)
            ).and(params.condition!);
          return model.dispatch('document/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model ? (params) => model.dispatch('document/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "80%", showFooter: true }
    }

    const form = ref<any>();
    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入標題', disabled: true } }
        },
        {
          field: 'PublishedDate',
          title: '出版日期',
          span: 12,
          titleWidth: 80,
          itemRender: {
            name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', disabled: true }
          }
        },
        {
          field: 'ReportUserId',
          title: '負責人',
          span: 12,
          slots: { default: "column-report-user-id" }
        },
        {
          field: 'HideReportUser',
          title: '出版時不顯示負責人於作者欄',
          span: 12,
          titleWidth: 230,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Keywords',
          title: '關鍵字',
          span: 24,
          slots: { default: "column-keywords" }
        },
        // {
        //   field: 'TopicType',
        //   title: '主題',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], props: { disabled: true } }
        // },
        // {
        //   title: '產業類別',
        //   span: 12,
        //   slots: { default: "column-programs" }
        // },
        // {
        //   field: 'ContentType',
        //   title: '內容類型',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.ContentType).map(e => { return { label: e.Name, value: e.Value } }) : [], props: { disabled: true } }
        // },
        // {
        //   field: 'SourceType',
        //   title: '來源類型',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.SourceType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        {
          span: 24,
          titleWidth: 80,
          slots: { default: "column-authors-list" }
        },
        // {
        //   field: 'ReportDate',
        //   title: '撰稿截止日期',
        //   span: 24,
        //   titleWidth: 100,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', disabled: true } }
        // },
        // {
        //   field: 'ProofUserId',
        //   title: '校稿人',
        //   span: 12,
        //   slots: { default: "column-proof-user-id" }
        // },
        // {
        //   field: 'ProofDate',
        //   title: '校稿截止日期',
        //   span: 12,
        //   titleWidth: 120,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', disabled: true } }
        // },
        // {
        //   field: 'IgnoreReroof',
        //   title: '重新送審不需校稿',
        //   span: 12,
        //   titleWidth: 150,
        //   itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        // },
        // {
        //   field: 'ReviewUserId',
        //   title: '審稿人',
        //   span: 12,
        //   slots: { default: "column-review-user-id" }
        // },
        // {
        //   field: 'ReviewDate',
        //   title: '審稿截止日期',
        //   span: 12,
        //   titleWidth: 120,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', disabled: true } }
        // },
        // {
        //   field: 'Page',
        //   title: '頁數',
        //   span: 12,
        //   titleWidth: 80,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        // },
        // {
        //   field: 'Remark',
        //   title: '備註',
        //   span: 24,
        //   titleWidth: 80,
        //   itemRender: { name: '$textarea' }
        // },
        // {
        //   span: 24,
        //   titleWidth: 80,
        //   slots: { default: "column-files-list" }
        // },
        {
          title: "研究送審",
          span: 24,
        },
        {
          span: 24,
          slots: { default: "column-check-list" }
        },
        {
          field: 'Comment',
          title: '意見',
          span: 24,
          itemRender: { name: '$textarea', props: { placeholder: '請輸入意見' } }
        },
        {
          span: 24,
          slots: { default: "column-check-list-files" }
        },
        {
          span: 24,
          slots: { default: "column-document-records" }
        },
      ],
      rules: {
        TopicType: [{ required: true }]
        // SourceType: [{ required: true }],
        // ContentType: [{ required: true }],
      }
    }

    const programSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      disabled: true,
      readonly: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          width: "40%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }, {
          field: "FullName",
          title: "全名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("program/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true)
            .and("ParentId", Operator.IsNotNull)
            .and(params.condition!);
          return model!.dispatch("program/query", params) // eslint-disable-line
        }
      }
    };

    const keywordSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("keyword/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition!);
          return model!.dispatch("keyword/query", params) // eslint-disable-line
        },
        insert: (value) => {
          const data = { entity: { Name: value.Name, Published: true } };
          return model!.dispatch("keyword/insert", data)
        },
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: true,
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => model!.dispatch("user/query", params) // eslint-disable-line
      }
    };

    const authorTable = ref<any>()

    const editTitleModalGrid = ref<any>();
    const editTitleModalForm = ref<any>();
    const editTitleModal = reactive({
      visible: false,
      selectedRow: {} as any,
      formData: {
        Id: "",
        OldTitle: "",
        Title: "",
        TitleReason: "",
        OldPublishedDate: "",
        PublishedDate: "",
        PublishedDateReason: "",
        OldReportUser: "",
        ReportUser: "",
        ReportUserReason: "",
        Comment: "",
        FileIds: [],
      },
      formOptions: {
        titleWidth: 80,
        titleAlign: 'right',
        items: [
          {
            field: 'OldTitle',
            title: '原標題',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入標題', disabled: true } }
          },
          {
            field: 'Title',
            title: '新標題',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入標題', clearable: true }, attrs: { type: 'text' } }
          },
          {
            field: 'TitleReason',
            title: '變更原因',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入原因', clearable: true }, attrs: { type: 'text' } }
          },
          {
            field: 'OldPublishedDate',
            title: '原出版日期',
            span: 8,
            titleWidth: 100,
            itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', disabled: true } }
          },
          {
            field: 'PublishedDate',
            title: '新出版日期',
            span: 8,
            titleWidth: 100,
            itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
          },
          {
            field: 'PublishedDateReason',
            title: '變更原因',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入原因', clearable: true }, attrs: { type: 'text' } }
          },
          {
            field: 'OldReportUser',
            title: '原撰稿人',
            span: 8,
            titleWidth: 80,
            slots: { default: "column-report-user-id" }
          },
          {
            field: 'ReportUser',
            title: '新撰稿人',
            span: 8,
            titleWidth: 80,
            slots: { default: "column-new-report-user-id" }
          },
          {
            field: 'ReportUserReason',
            title: '變更原因',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入原因', clearable: true }, attrs: { type: 'text' } }
          },
          {
            span: 24,
            slots: { default: "files" }
          }
        ]
      } as VxeFormProps,
      uploadOptions: {
        promises: {
          query() {
            return model?.dispatch('files/query', {
              condition: new Condition("DocumentRecordId", Operator.IsNull)
                .and("Type", Operator.Equal, 4)
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', fileType: 4 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        editTitleModal.visible = true;
        editTitleModal.selectedRow = row;
        editTitleModal.formData = {
          Id: row.Id,
          OldTitle: row.Title,
          OldPublishedDate: row.PublishedDate,
          OldReportUser: row.ReportUserId,
        } as any;
      },
      submit() {
        if (!editTitleModal.formData.Title && !editTitleModal.formData.PublishedDate && !editTitleModal.formData.ReportUser) {
          isDisabled.value = false;
          return;
        }

        editTitleModal.formData.FileIds = editTitleModalGrid.value.getData().map((e: any) => e.Id);
        model!.dispatch("document/requestChange", editTitleModal.formData).then(() => {
          editTitleModal.selectedRow = {} as any;
          editTitleModal.visible = false;
          grid.value.refresh();
        }, failure => CloudFun.send('error', { subject: '操作失敗！', content: failure }))
      }
    })

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        title: "上傳研究報告",
        promises: {
          query() {
            return fileModal.selectedRow
              ? model?.dispatch('files/query', {
                condition: new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id)
                  .and("Type", Operator.Equal, 5)
              })
              : undefined;
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 5 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.visible = true;
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const reportModalGrid = ref<any>();
    const reportModal = reactive({
      visible: false,
      readyToApprove: false,
      // formData: {
      //   Id: "",
      //   ReportChecklist: {
      //     IsTemplate: false,
      //     IsAbstract: false,
      //     IsContent: false,
      //     IsChart: false,
      //     IsWord: false
      //   },
      //   Comment: "",
      //   FileIds: []
      // },
      // formOptions: {
      //   titleWidth: 80,
      //   titleAlign: 'right',
      //   items: [
      //     {
      //       span: 24,
      //       slots: { default: "check-list" }
      //     },
      //     {
      //       field: 'Comment',
      //       title: '意見',
      //       span: 24,
      //       itemRender: { name: '$textarea', props: { placeholder: '請輸入意見' } }
      //     },
      //     {
      //       span: 24,
      //       slots: { default: "check-list-files" }
      //     },
      //   ]
      // } as VxeFormProps,
      uploadOptions: {
        promises: {
          query() {
            return model?.dispatch('files/query', {
              condition: new Condition("DocumentRecordId", Operator.IsNull)
                .and("Type", Operator.Equal, 3).and("DocumentId", Operator.Equal, fileModal.selectedRow.Id)
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', fileType: 3, entityId: 'Document_' + fileModal.selectedRow.Id });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      // show(row: any) {
      //   reportModal.visible = true;
      //   reportModal.formData = {
      //     Id: row.Id,
      //     ReportChecklist: {
      //       IsTemplate: false,
      //       IsAbstract: false,
      //       IsContent: false,
      //       IsChart: false,
      //       IsWord: false
      //     },
      //     Comment: "",
      //     FileIds: []
      //   };
      // },
      async approve(data: any, callback: any) {
        try {
          if (!reportModal.readyToApprove) { // save
            callback();
          } else { // proof
            grid.value.editingRow.FileIds = reportModalGrid.value.getData().map((e: any) => e.Id);
            await model!.dispatch("document/update", grid.value.editingRow);
            await model!.dispatch("document/proof", grid.value.editingRow);
            grid.value.refresh();
            grid.value.isModalPopup = false;
          }
        } catch (failure: any) {
          CloudFun.send('error', { subject: '操作失敗！', content: failure });
        } finally {
          reportModal.readyToApprove = false;
        }
      }
    });

    return {
      grid,
      gridOptions,
      form,
      formOptions,
      model,
      programSelectorOptions,
      keywordSelectorOptions,
      userSelectorOptions,
      authorTable,
      editTitleModalGrid,
      editTitleModalForm,
      editTitleModal,
      fileGrid,
      fileModal,
      reportModalGrid,
      reportModal,
      isDisabled
    }
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity)
      row.ReportChecklist = {
        IsTemplate: false,
        IsAbstract: false,
        IsContent: false,
        IsChart: false,
        IsWord: false
      };
      row.Comment = "";
      row.FileIds = [];
      this.fileModal.show(row);
      callback();
    }
  }
})
